<template>
  <pz-form-flujo
    v-model="formulario2['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form2_persona.UY.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario2"
      status-icon
      :rules="rules_formulario2"
    >

      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.ingreso_tienda"
            :label="$t('forms_flujo.form2_persona.SP.ingreso_tienda')"
            :error="errors.get('ingreso_tienda')"
          >
            <el-input
              v-model="formulario2.info_extra.ingreso_tienda"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_persona.SP.ingreso_tienda')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.gasto_tienda"
            :label="$t('forms_flujo.form2_persona.SP.gasto_tienda')"
            :error="errors.get('gasto_tienda')"
          >
            <el-input
              v-model="formulario2.info_extra.gasto_tienda"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_persona.SP.gasto_tienda')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.tipo_tienda"
            :label="$t('forms_flujo.form2_persona.SP.tipo_tienda')"
            :error="errors.get('tipo_tienda')"
          >
            <el-select
              ref="info_extra.tipo_tienda"
              v-model="formulario2.info_extra.tipo_tienda"
              clearable
              :placeholder="$t('forms_flujo.form2_persona.SP.tipo_tienda')"
            >
              <el-option
                v-for="item in data_tipo_tienda"
                :key="item.id"
                :label="item.name"
                :value="item.name"
                :placeholder="$t('forms_flujo.form2_persona.SP.tipo_tienda')"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="tiempo_tienda"
            :label="$t('forms_flujo.form2_persona.SP.tiempo_tienda')"
            :error="errors.get('tiempo_tienda')"
          >
            <el-input
              v-model="formulario2.nro_tiempo_tienda"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_persona.SP.tiempo_tienda')"
              class="input-with-select"
            >
            <el-select
             slot="prepend"
             ref="tiempo_tienda"
              v-model="formulario2.tiempo_tienda"
             style="width: 110px;"
            >
              <el-option
                v-for="item in data_tiempo_tienda"
                :key="item.name"
                :label="item.name"
                :value="item.name"
                :placeholder="$t('forms_flujo.form2_persona.SP.tiempo_tienda')"
              />
            </el-select>
            </el-input>
          </el-form-item>

        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.nombre_tienda"
            :label="$t('forms_flujo.form2_persona.SP.nombre_tienda')"
            :error="errors.get('nombre_tienda')"
          >
            <el-input
              v-model="formulario2.info_extra.nombre_tienda"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_persona.SP.nombre_tienda')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <pz-direction
          v-model="formulario2.direccion"
          name-direction
      />
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.producto_nestle"
            :label="$t('forms_flujo.form2_persona.SP.producto_nestle')"
            :error="errors.get('producto_nestle')"
          >
          <el-select
            ref="info_extra.producto_nestle"
            v-model="formulario2.info_extra.producto_nestle"
            clearable
            :placeholder="$t('forms_flujo.form2_persona.SP.producto_nestle')"
          >
            <el-option
              v-for="item in data_producto"
              :key="item.id"
              :label="item.id"
              :value="item.id"
              :placeholder="$t('forms_flujo.form2_persona.SP.producto_nestle')"
            />
          </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.patente"
            :label="$t('forms_flujo.form2_persona.SP.patente')"
            :error="errors.get('patente')"
            :hidden="isnestle"
          >
            <el-input
              v-model="formulario2.info_extra.patente"

              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_persona.SP.patente')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="info_extra.energia_electrica"
                  :label="$t('forms_flujo.form1_persona.UY.energia_electrica')"
                  :error="errors.get('energia_electrica')"
                  :hidden="isnestle"
          >
            <el-select
                    ref="info_extra.energia_electrica"
                    v-model="formulario2.info_extra.energia_electrica"
                    clearable
                    :placeholder="$t('forms_flujo.form1_persona.UY.energia_electrica')"
            >
              <el-option
                      v-for="item in data_energia_electrica"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                      :placeholder="$t('forms_flujo.form1_persona.UY.energia_electrica')"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="info_extra.correlativo"
                  :label="$t('forms_flujo.form2_persona.SP.correlativo')"
                  :error="errors.get('correlativo')"
                  :hidden="isnestle"
          >
            <el-input
                    v-model="formulario2.info_extra.correlativo"
                    :type="'text'"
                    auto-complete="no"
                    :placeholder="$t('forms_flujo.form2_persona.SP.correlativo')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="info_extra.contador"
                  :label="$t('forms_flujo.form2_persona.SP.contador')"
                  :error="errors.get('contador')"
                  :hidden="isnestle"
          >
            <el-input
                    v-model="formulario2.info_extra.contador"
                    :type="'text'"
                    auto-complete="no"
                    :placeholder="$t('forms_flujo.form2_persona.SP.contador')"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>
    import {required} from "@/commons/utils/Rules";
    import baseform2 from '../baseForm2'
    import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
    import PzDirection from "@/commons/forms/Fields/PzDirection";
    import {mapGetters} from "vuex";
    export default {
        name: 'PzPersonFormExtImpacta',
        components: {PzFormFlujo,PzDirection},
        mixins: [baseform2],
        data() {
            return {
                data_tipo_tienda: [
                    {id: 'propia', name: this.$t('forms_flujo.form2_persona.SP.propia')},
                    {id: 'alquilada', name: this.$t('forms_flujo.form2_persona.SP.alquilada')},
                    {id: 'familiar', name: this.$t('forms_flujo.form2_persona.SP.familiar')},
                    {id: 'trabajador', name: this.$t('forms_flujo.form2_persona.SP.trabajador')}
                ],
                data_tiempo_tienda: [
                    {id: 'meses', name: this.$t('forms_flujo.form2_persona.SP.meses')},
                    {id: 'anio', name: this.$t('forms_flujo.form2_persona.SP.anio')}
                ],
                data_producto: [
                    {id: 'Si'},
                    {id: 'No'}
                ],
                data_energia_electrica: [
                    {id: 'EGGSA', name: this.$t('forms_flujo.form1_persona.UY.EGGSA')},
                    {id: 'ENERGUATE', name: this.$t('forms_flujo.form1_persona.UY.ENERGUATE')},
                    {id: 'Otros', name: this.$t('forms_flujo.form1_persona.UY.otros')}
                ],
                formulario2: {
                    info_extra: {
                        ingreso_tienda: '',
                        gasto_tienda: '',
                        tipo_tienda: '',
                        patente: '',
                        nombre_tienda: '',
                        producto_nestle: '',
                        energia_electrica:'',
                        contador:'',
                        correlativo:''

                    },
                    direccion: {},
                    nro_tiempo_tienda: '',
                    tiempo_tienda: '',

                },
                rules_formulario2:
                    {
                        info_extra: {
                            ingreso_tienda: [required('')],
                            gasto_tienda: [required('')],
                            tipo_tienda: [required('')],
                            producto_nestle: [required('')],
                        },
                    },
            };
        },
        computed: {
            ...mapGetters(['applicant']),
                isnestle(){
                    return !(this.formulario2.info_extra.producto_nestle && this.formulario2.info_extra.producto_nestle=='No')
                }
        },
        created: function () {
        },
        methods: {},
    }
</script>